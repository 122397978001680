import { AnalyticsProductInfo } from "../analytics/personalized-cards-analytics.js"

const divHomeProductInfo = document.getElementById('divHomeProductInfo');
const serviceTag = divHomeProductInfo.dataset.servicetag;
const activeProdCode = divHomeProductInfo.dataset.activeprodcode;
const rvpsAssetId = divHomeProductInfo.dataset.rvpsassetid;
const urlPath = `/support/home/${Dell.Metrics.sc.language}-${Dell.Metrics.sc.country}/productdetect/getproduct`;

fetch(urlPath,
        {
            method: "POST",
            body: JSON.stringify({
                "ServiceTag": serviceTag,
                "ActiveProdCode": activeProdCode,
                "RvpsAssetId": rvpsAssetId
            }),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
    .then((response) => response.json())
    .then((data) => {
        if (data != null && data != undefined) {
            if (data.activeProdCode !== "" || data.serviceTag !== "" || data.rvpsAssetId !== "") {
                const ele = document.getElementById("divHomeProductInfo");
                const html = `${data.productKey} <a href='${data.productUrl}' id="ancProductCode" class='dds__link--standalone dds__break-word' data-stcookies="${data.stCookie}">${data.productName}<i class='dds__icon dds__icon--arrow-right dds__font-size-normal dds__pl-1' style="vertical-align:00.2em;" aria-hidden='true'></i></a>`;
                ele.innerHTML = html;
                ele.classList.remove("dds__invisible");
                if (data.stCookie !== null && data.stCookie !== "") {
                    const productObject = {
                        name: `${data.productName}`,
                        serviceTag: `${data.stCookie}`,
                        productCode: `${data.productCode}`
                    }
                    sessionStorage.setItem('thispc', JSON.stringify(productObject));
                }
                const productInfoEscElem = document.getElementById("productInfoEsc");
                const productInfoDividerElem = document.getElementById("productInfoDivider");

                if (data.expressServiceCode == null || data.expressServiceCode == "0") {
                    productInfoEscElem.remove();
                    productInfoDividerElem.remove();
                }
                else {
                    productInfoEscElem.innerHTML = productInfoEscElem.innerHTML.replace('</span>', `</span> ${data.expressServiceCode}`);
                }

                AnalyticsProductInfo();
            }
        }
    })
    .catch((ex) => {
        console.error(`Product Name Api Error :- ${ex}`);
    });